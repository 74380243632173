import React, { useEffect, useState } from 'react';
import logo from '../assets/images/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ContactUsModal from '../Component/ContactUsModal';
import { getapi } from '../Api/Api';
import Preloader from '../Component/PreLoder';
import { currenturl } from '../Api/BaseUrl';

const Header = () => {
    const [isActive, setIsActive] = useState(false);
    const [loading, setloading] = useState(false)
    const [venturedata, setventuredata] = useState([])
    const [mobilemenu, setmobilemenu] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown visibility
    const [show, setShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const otherpage = location.pathname === '/';

    const handleget = async () => {
        let res = await getapi(`ventures`)
        setloading(true)

        if (res.data.error == 0) {

            setventuredata(res.data.data)
            setloading(false)
        } else {
            setloading(false)
        }
    }

    useEffect(() => {
        handleget()
    }, [])

    const handleClose = () => {
        setShow(false);
        const now = new Date();
        const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        const millisecondsUntilEndOfDay = endOfDay - now;
        sessionStorage.setItem('modalclose', 'true');
        setTimeout(() => {
            sessionStorage.removeItem('modalclose');
        }, millisecondsUntilEndOfDay);
    };

    const handleShow = () => setShow(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handlecheck = () => {
        const modalClosed = sessionStorage.getItem('modalclose');
        setShow(modalClosed !== 'true');
    };

    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;
            setIsActive(scroll >= 110);
            if (scroll < 110) handlecheck();
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleToggleClick = () => {
        setmobilemenu(!mobilemenu);
    };

    const handleNavLinkClick = () => {
        setIsActive(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
    };

    const subMenuItems = [
        {
            id: 1,
            title: 'About Us',
            link: `/about`
        },
        {
            id: 2,
            title: 'Ventures',
            link: '#',
            subMenu: [

                ...venturedata?.map((item, index) => ({
                    id: 4,
                    title: item.title,
                    link: `/venture/${item.url}` // Assuming item.url contains the necessary slug
                }))
            ]
        },
        {
            id: 3,
            title: 'Service',
            link: `${otherpage ? "#services" : "/"}`
        },
        {
            id: 4,
            title: 'Portfolio',
            link: `${otherpage ? "#portfolio" : "/"}`
        },
        {
            id: 5,
            title: 'Career',
            link: '/career'
        },
        {
            id: 6,
            title: 'Contact',
            link: '/contact'
        }
    ];



    return (
        <>
            {loading && <Preloader />}
            <header className="header-area">
                <div className={`header-nav ${isActive ? "sticky" : ""}`}>
                    {/* <div className={`${otherpage ? "container" : "container-fluid"}`}> */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="navigation">
                                    <nav className="navbar navbar-expand-lg navbar-light">
                                        <div className="d-flex align-items-center">
                                            {!otherpage && (
                                                <div className="backbtn me-md-5 me-2">
                                                    <button className="btn px-3 rounded-3" onClick={() => navigate(-1)}>
                                                        <i className="fa-solid fa-angle-left text-white"></i>
                                                    </button>
                                                </div>
                                            )}
                                            <Link className="navbar-brand" to='/'><img loading="lazy" src={logo} alt="Logo" /></Link>
                                        </div>

                                        <button className={`navbar-toggler ${mobilemenu ? 'active' : ''}`} type="button" onClick={handleToggleClick}>
                                            <span className="toggler-icon"></span>
                                            <span className="toggler-icon"></span>
                                            <span className="toggler-icon"></span>
                                        </button>

                                        <div className={`collapse navbar-collapse sub-menu-bar ${mobilemenu ? 'show' : ''}`} id="navbarSupportedContent">
                                            <ul className="navbar-nav ml-auto">
                                                {subMenuItems.map(item => (
                                                    <li className="nav-item" key={item.id}>
                                                        {item.subMenu ? (
                                                            <a
                                                                href='#'
                                                                className="nav-link dropdown-toggle"
                                                                onClick={toggleDropdown}
                                                                aria-expanded={dropdownOpen}
                                                            >
                                                                {item.title}
                                                            </a>
                                                        ) : (
                                                            <a className="nav-link" href={item.link} onClick={handleNavLinkClick}>{item.title}</a>
                                                        )}

                                                        {item.subMenu && dropdownOpen && (
                                                            <ul className="dropdown-menu  show">
                                                                {item.subMenu.map(subItem => (
                                                                    <li key={subItem.id} className=''>
                                                                        <Link className="dropdown-item px-3" style={{ fontSize: "14px" }} to={subItem.link} onClick={handleNavLinkClick}>{subItem.title}</Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className={`${otherpage ? "navbar-btn d-none d-sm-block" : "navbar-btn d-none d-sm-block pe-5"}`}>
                                            <a className="main-btn" href="#" onClick={handleShow}>Get Started</a>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Modal show={show} size='lg' className='book-open' centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsModal show={show} setShow={setShow} check={true} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Header;
